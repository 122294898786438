p {
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.ql-container.ql-snow {
  height: auto;
}

.ql-disabled .ql-editor > * {
  cursor: default;
  color: #707ea4;
}

.ql-disabled.ql-container.ql-snow {
  border-color: #d3d9e6;
}

.ql-editor li {
  padding-left: 0px;
}

.ql-editor li[data-list='bullet'].ql-indent-1 > .ql-ui:before {
  content: '\25CB';
}

.ql-editor li[data-list='bullet'].ql-indent-2 > .ql-ui:before {
  content: '\25AA';
}

.ql-editor li[data-list='bullet'].ql-indent-3 > .ql-ui:before {
  content: '\25AA';
}

.ql-editor li[data-list='bullet'].ql-indent-4 > .ql-ui:before {
  content: '\25AA';
}

.ql-editor li[data-list='bullet'].ql-indent-5 > .ql-ui:before {
  content: '\25AA';
}

.ql-editor li[data-list='bullet'].ql-indent-6 > .ql-ui:before {
  content: '\25AA';
}

.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 20px;
}

.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 40px;
}

.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 60px;
}

.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 80px;
}

.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 100px;
}

.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 120px;
}
